interface SentryConfigData {
    SENTRY_DSN: string;
    SENTRY_RELEASE: string;
}

const SentryConfig: SentryConfigData = {
    SENTRY_DSN: process.env.GATSBY_SENTRY_DSN || '',
    SENTRY_RELEASE: process.env.GATSBY_SENTRY_RELEASE || ''
};

export default SentryConfig;
