import {
    addGlobalEventProcessor,
    captureException,
    configureScope,
    init
} from '@sentry/browser';
import Cookies from 'js-cookie';

import { SERVER_HOST } from '~/constants/hosts';
import SentryConfig from '~/constants/SentryConfig';
import detectBrowser from '~/libs/detectBrowser';

addGlobalEventProcessor((event) => {
    event.extra = event.extra || {};
    event.extra.workedGlobalProcessor = true;

    const handled = event.extra?.handled || false;
    let requireURL = event.request?.url;
    if (typeof requireURL !== 'string')
        requireURL = window?.location?.href || '';

    // 핸들링 되지 않은 에러일 경우 noti.afreecatv.com 이 아니면 sentry 무시
    if (!handled && !requireURL.startsWith(SERVER_HOST)) {
        return null;
    }

    return event;
});

init({
    dsn: SentryConfig.SENTRY_DSN,
    release: SentryConfig.SENTRY_RELEASE,
    sampleRate: 1,
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',

        // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/4
        'Non-Error exception captured',
        'Non-Error promise rejection captured'
    ],
    beforeSend: (event) => {
        // extra 추가
        event.extra = event.extra || {};
        event.extra.workedBeforeSend = true;

        return event;
    }
});

const reportSentry = <E extends Error>(error: E) => {
    // 추가 데이터
    configureScope((scope) => {
        scope.setExtra('cookies', Cookies.get());
        scope.setExtra('browser detect', detectBrowser());
        scope.setExtra('handled', true);
    });

    // 센트리 전송
    captureException(error);
};

export default reportSentry;
