import axios from 'axios';

import ErrorResultCode from '~/constants/ErrorResultCode';
import { IS_PRODUCTION } from '~/constants/etc';
import { LOGIN_HOST } from '~/constants/hosts';
import CustomError from '~/errors/CustomError';
import HTTPError from '~/errors/HTTPError';
import Warning from '~/errors/Warning';
import { getDefaultErrorMessage } from '~/libs/helpers';
import reportSentry from '~/libs/Sentry';

const consoleLog = <E>(error: E, message: string) => {
    if (!(error instanceof Error)) return;

    IS_PRODUCTION ? productionLog(error) : developmentLog(error, message);
};

const productionLog = (error: Error) => {
    let message = getDefaultErrorMessage();
    if (error instanceof CustomError) {
        message = error.productionMessage;
    }

    error instanceof Warning ? console.warn(message) : console.error(message);
};

const developmentLog = (error: Error, message: string) => {
    const errorMessage = message + ` [${error.message}]`;

    error instanceof Warning
        ? console.warn(errorMessage)
        : console.error(errorMessage);
};

/**
 * sentry 에 error 전달
 *
 * @param {Error} error error
 * @returns {void}
 */
const captureSentryException = <E extends Error>(error: E): void => {
    // sentry 보내지 않음
    if (error instanceof Warning || axios.isCancel(error)) {
        return;
    }

    // sentry 에러 전송
    reportSentry(error);
};

const errorHandler = <E>(error: E, message = 'Error occurred.') => {
    if (!(error instanceof Error)) {
        return;
    }

    if (error instanceof HTTPError) {
        const { data } = error;
        const { RESULT } = data;

        switch (RESULT) {
            // 로그인 오류
            case ErrorResultCode.LOGIN_ERROR_CODE:
                window.location.href = `${LOGIN_HOST}/afreeca/login.php?szFrom=full&request_uri=${window.encodeURIComponent(
                    window.location.href
                )}`;
                break;

            default:
        }
    }

    // sentry 에러 추가
    captureSentryException(error);

    // browser log 에 logging
    consoleLog(error, message);
};

export default errorHandler;
