export const SERVER_HOST = `https://${process.env.GATSBY_SERVER_HOST}`;

// resource hosts
export const RES_HOST = 'https://res.afreecatv.com';
export const WWW_HOST = 'https://www.afreecatv.com';
export const STATIC_HOST = 'https://static.afreecatv.com';

// service hosts
export const LIVE_HOST = 'https://live.afreecatv.com';
export const BJ_HOST = 'https://bj.afreecatv.com';

// login hosts
export const LOGIN_HOST = 'https://login.afreecatv.com';
export const MEMBER_HOST = 'https://member.afreecatv.com';
